var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"fill-height"},[_c('vue-element-loading',{attrs:{"active":_vm.submiting}}),_c('v-col',[_c('v-sheet',{attrs:{"height":"64"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"grey darken-2"},on:{"click":_vm.setToday}},[_vm._v(" Sot ")]),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.prev}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.next}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-right ")])],1),(_vm.$refs.calendar)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"grey darken-2"},on:{"click":_vm.setEffective}},[_vm._v(" Data e hyrjes në fuqi ")])],1)],1),_c('v-sheet',{attrs:{"height":"600"}},[_c('v-calendar',{ref:"calendar",attrs:{"color":"primary","events":_vm.events,"event-color":_vm.getEventColor,"type":_vm.type},on:{"click:event":_vm.showEvent,"change":_vm.updateRange},model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"activator":_vm.selectedElement,"offset-x":""},model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}},[_c('v-card',{attrs:{"min-width":"350px","flat":""}},[_c('v-toolbar',{attrs:{"dark":"","color":_vm.selectedEvent.color}},[_c('v-toolbar-title',{staticClass:"white--text",domProps:{"innerHTML":_vm._s(_vm.selectedEvent.details)}}),_c('v-spacer'),(_vm.trip.status_id == 1 
            && _vm.selectedEvent.suspension_id == null)?_c('div',[_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(
                      _vm.selectedEvent.status != 0 &&
                      _vm.suspension_id != null &&
                      _vm.suspension_id == 'none'
                    )?_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1 error",attrs:{"outlined":""}},'v-btn',attrs,false),on),[_vm._v(" Pezulloni "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-motion-pause ")])],1):_vm._e()]}}],null,false,721825697)},[_c('v-list',_vm._l((_vm.suspensionPeriods),function(suspensionPeriod,i){return _c('v-list-item',{key:i,on:{"click":function($event){return _vm.suspend(_vm.selectedEvent, suspensionPeriod.value)}}},[_c('v-list-item-title',[_vm._v(_vm._s(suspensionPeriod.title))])],1)}),1)],1)],1):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.selectedOpen = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[(_vm.selectedEvent.status == 0)?_c('div',{staticClass:"my-2"},[_c('strong',{staticClass:"error--text"},[_vm._v("I pezulluar")])]):_vm._e(),(_vm.selectedEvent.driver)?_c('div',{staticClass:"my-2"},[_vm._v(" Shoferi: "),_c('strong',[_vm._v(_vm._s(_vm.selectedEvent.driver.name))])]):_vm._e(),_c('v-timeline',{attrs:{"align-top":""}},_vm._l((_vm.trip.trip_details),function(detail,index){return _c('v-timeline-item',{key:detail.id},[_c('v-row',{staticClass:"pt-1"},[_c('v-col',[_c('strong',[_vm._v(_vm._s(detail.stop.name))]),_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(detail.planned_timestamp)+" ")])])],1)],1)}),1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }